import {
  RetrieveMarketConsultantAction,
  RETRIEVE_MARKET_CONSULTANT,
  RETRIEVE_MARKET_CONSULTANT_SUCCESS,
  RetrieveMarketConsultantSuccessAction,
  RetrieveMarketConsultantFailureAction,
  RETRIEVE_MARKET_CONSULTANT_FAILURE,
  RetrieveUserConsultantListAction,
  RETRIEVE_USER_CONSULTANT_LIST,
  RETRIEVE_USER_CONSULTANT_LIST_SUCCESS,
  RetrieveUserConsultantListSuccessAction,
  RetrieveUserConsultantListFailureAction,
  RETRIEVE_USER_CONSULTANT_LIST_FAILURE,
  RETRIEVE_CONSULTANT_BY_SLUG,
  RETRIEVE_CONSULTANT_BY_SLUG_SUCCESS,
  RETRIEVE_CONSULTANT_BY_SLUG_FAILURE,
  RetrieveConsultantBySlugAction,
  RetrieveConsultantBySlugSuccessAction,
  RetrieveConsultantBySlugFailureAction,
  RETRIEVE_GENERAL_CONSULTANT,
  RETRIEVE_GENERAL_CONSULTANT_SUCCESS,
  RETRIEVE_GENERAL_CONSULTANT_FAILURE,
  RetrieveGeneralConsultantAction,
  RetrieveGeneralConsultantSuccessAction,
  RetrieveGeneralConsultantFailureAction,
  SET_MODAL_CONSULTANT,
  SetModalConsultantAction,
  SetConsultantEmailDataAction,
  SET_CONSULTANT_EMAIL_DATA,
  SEND_CONSULTANT_EMAIL,
  SendConsultantEmailAction,
  SendConsultantEmailSuccessAction,
  SEND_CONSULTANT_EMAIL_FAILURE,
  SEND_CONSULTANT_EMAIL_SUCCESS,
  SendConsultantEmailFailureAction
} from './types';

export const doSetModalConsultant = (
  consultant?: SetModalConsultantAction['consultant'],
  shouldClearOnClose?: boolean
): SetModalConsultantAction => ({
  type: SET_MODAL_CONSULTANT,
  consultant,
  shouldClearOnClose
});

export const doSetConsultantEmailData = (
  data?: SetConsultantEmailDataAction['data']
): SetConsultantEmailDataAction => ({
  type: SET_CONSULTANT_EMAIL_DATA,
  data
});

export const doRetrieveMarketConsultant = (
  marketSlug: RetrieveMarketConsultantAction['marketSlug']
): RetrieveMarketConsultantAction => ({
  type: RETRIEVE_MARKET_CONSULTANT,
  marketSlug
});

export const doRetrieveMarketConsultantSuccess = (
  marketConsultant: RetrieveMarketConsultantSuccessAction['marketConsultant']
): RetrieveMarketConsultantSuccessAction => ({
  type: RETRIEVE_MARKET_CONSULTANT_SUCCESS,
  marketConsultant
});

export const doRetrieveMarketConsultantFailure = (
  error: RetrieveMarketConsultantFailureAction['error']
): RetrieveMarketConsultantFailureAction => ({
  type: RETRIEVE_MARKET_CONSULTANT_FAILURE,
  error
});

export const doRetrieveUserConsultantList = (): RetrieveUserConsultantListAction => ({
  type: RETRIEVE_USER_CONSULTANT_LIST
});

export const doRetrieveUserConsultantListSuccess = (
  consultantList: RetrieveUserConsultantListSuccessAction['consultantList']
): RetrieveUserConsultantListSuccessAction => ({
  type: RETRIEVE_USER_CONSULTANT_LIST_SUCCESS,
  consultantList
});

export const doRetrieveUserConsultantListFailure = (
  error: RetrieveUserConsultantListFailureAction['error']
): RetrieveUserConsultantListFailureAction => ({
  type: RETRIEVE_USER_CONSULTANT_LIST_FAILURE,
  error
});

export const doRetrieveConsultantBySlug = (
  consultantSlug: RetrieveConsultantBySlugAction['consultantSlug']
): RetrieveConsultantBySlugAction => ({
  type: RETRIEVE_CONSULTANT_BY_SLUG,
  consultantSlug
});

export const doRetrieveConsultantBySlugSuccess = (
  consultant: RetrieveConsultantBySlugSuccessAction['consultant']
): RetrieveConsultantBySlugSuccessAction => ({
  type: RETRIEVE_CONSULTANT_BY_SLUG_SUCCESS,
  consultant
});

export const doRetrieveConsultantBySlugFailure = (
  error: RetrieveConsultantBySlugFailureAction['error']
): RetrieveConsultantBySlugFailureAction => ({
  type: RETRIEVE_CONSULTANT_BY_SLUG_FAILURE,
  error
});

export const doRetrieveGeneralConsultant = (): RetrieveGeneralConsultantAction => ({
  type: RETRIEVE_GENERAL_CONSULTANT
});

export const doRetrieveGeneralConsultantSuccess = (
  consultant: RetrieveGeneralConsultantSuccessAction['consultant']
): RetrieveGeneralConsultantSuccessAction => ({
  type: RETRIEVE_GENERAL_CONSULTANT_SUCCESS,
  consultant
});

export const doRetrieveGeneralConsultantFailure = (
  error: RetrieveGeneralConsultantFailureAction['error']
): RetrieveGeneralConsultantFailureAction => ({
  type: RETRIEVE_GENERAL_CONSULTANT_FAILURE,
  error
});

export const doSendConsultantEmail = (data: SendConsultantEmailAction['data']): SendConsultantEmailAction => ({
  type: SEND_CONSULTANT_EMAIL,
  data
});

export const doSendConsultantEmailSuccess = (): SendConsultantEmailSuccessAction => ({
  type: SEND_CONSULTANT_EMAIL_SUCCESS
});

export const doSendConsultantEmailFailure = (
  error?: SendConsultantEmailFailureAction['error']
): SendConsultantEmailFailureAction => ({
  type: SEND_CONSULTANT_EMAIL_FAILURE,
  error
});
