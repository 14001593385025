import {
  RETRIEVE_REPORT,
  RETRIEVE_REPORT_SUCCESS,
  RETRIEVE_REPORT_FAILURE,
  SEND_REPORT,
  SEND_REPORT_SUCCESS,
  SEND_REPORT_FAILURE,
  RETRIEVE_REPORT_UUID,
  RETRIEVE_REPORT_UUID_SUCCESS,
  RETRIEVE_REPORT_UUID_FAILURE,
  RETRIEVE_REPORT_FULL,
  RETRIEVE_REPORT_FULL_SUCCESS,
  RETRIEVE_REPORT_FULL_FAILURE,
  RetrieveNavigatorReportUuidAction,
  RetrieveNavigatorReportUuidSuccessAction,
  RetrieveNavigatorReportUuidFailureAction,
  RetrieveNavigatorReportAction,
  RetrieveNavigatorReportSuccessAction,
  RetrieveNavigatorReportFailureAction,
  SendNavigatorReportAction,
  SendNavigatorReportSuccessAction,
  SendNavigatorReportFailureAction,
  RetrieveNavigatorReportFullAction,
  RetrieveNavigatorReportFullSuccessAction,
  RetrieveNavigatorReportFullFailureAction,
  ToggleReportTodoDoneAction,
  SetReportTodoDoneAction,
  UpdateNavigatorReportAction,
  TOGGLE_REPORT_TODO_DONE,
  SET_REPORT_TODO_DONE,
  UPDATE_REPORT,
  UPDATE_REPORT_SUCCESS,
  UPDATE_REPORT_FAILURE,
  UpdateNavigatorReportSuccessAction,
  UpdateNavigatorReportFailureAction
} from './types';
import { Report, Question } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doRetrieveReport = (uuid: string): RetrieveNavigatorReportAction => ({
  type: RETRIEVE_REPORT,
  uuid
});

export const doRetrieveReportFull = (): RetrieveNavigatorReportFullAction => ({
  type: RETRIEVE_REPORT_FULL
});

export const doRetrieveReportSuccess = (report: any): RetrieveNavigatorReportSuccessAction => ({
  type: RETRIEVE_REPORT_SUCCESS,
  report
});

export const doRetrieveReportFullSuccess = (report: any): RetrieveNavigatorReportFullSuccessAction => ({
  type: RETRIEVE_REPORT_FULL_SUCCESS,
  report
});

export const doRetrieveReportFailure = (error?: RequestResult<void>): RetrieveNavigatorReportFailureAction => ({
  type: RETRIEVE_REPORT_FAILURE,
  error
});

export const doRetrieveReportFullFailure = (error?: RequestResult<void>): RetrieveNavigatorReportFullFailureAction => ({
  type: RETRIEVE_REPORT_FULL_FAILURE,
  error
});

export const doRetrieveReportUuid = (): RetrieveNavigatorReportUuidAction => ({
  type: RETRIEVE_REPORT_UUID
});

export const doRetrieveReportUuidSuccess = (uuid: string): RetrieveNavigatorReportUuidSuccessAction => ({
  type: RETRIEVE_REPORT_UUID_SUCCESS,
  uuid
});

export const doRetrieveReportUuidFailure = (error?: RequestResult<void>): RetrieveNavigatorReportUuidFailureAction => ({
  type: RETRIEVE_REPORT_UUID_FAILURE,
  error
});

export const doSendReport = (
  questions: Question[],
  marketId: number,
  HSCodeId: number,
  keepCurrentReport?: boolean
): SendNavigatorReportAction => ({
  type: SEND_REPORT,
  questions,
  marketId,
  HSCodeId,
  keepCurrentReport
});

export const doSendReportSuccess = (uuid: Report['uuid']): SendNavigatorReportSuccessAction => ({
  type: SEND_REPORT_SUCCESS,
  uuid
});

export const doSendReportFailure = (error?: RequestResult<void>): SendNavigatorReportFailureAction => ({
  type: SEND_REPORT_FAILURE,
  error
});

export const doUpdateReport = (question: Question): UpdateNavigatorReportAction => ({
  type: UPDATE_REPORT,
  question
});

export const doUpdateReportSuccess = (): UpdateNavigatorReportSuccessAction => ({
  type: UPDATE_REPORT_SUCCESS
});

export const doUpdateReportFailure = (): UpdateNavigatorReportFailureAction => ({
  type: UPDATE_REPORT_FAILURE
});

export const doToggleReportTodoDone = (
  questionId: ToggleReportTodoDoneAction['questionId'],
  reportTask: ToggleReportTodoDoneAction['reportTask'],
  checked: ToggleReportTodoDoneAction['checked']
): ToggleReportTodoDoneAction => ({
  type: TOGGLE_REPORT_TODO_DONE,
  questionId,
  reportTask,
  checked
});

export const doSetReportTodoDone = (
  questionId: SetReportTodoDoneAction['questionId'],
  reportTask: SetReportTodoDoneAction['reportTask'],
  checked: SetReportTodoDoneAction['checked']
): SetReportTodoDoneAction => ({
  type: SET_REPORT_TODO_DONE,
  questionId,
  reportTask,
  checked
});
