import { RequestResult } from '../../../interfaces/requests';
import { CMSEvent } from './interfaces';
import {
  RetrieveAction,
  RetrieveEventsAction,
  RetrieveEventsFailureAction,
  RetrieveEventsLoadingAction,
  RetrieveEventsSuccessAction,
  RetrieveEventTeasersAction,
  RetrieveEventTeasersFailureAction,
  RetrieveEventTeasersLoadingAction,
  RetrieveEventTeasersSuccessAction,
  RetrieveFailureAction,
  RetrieveMarketEventsAction,
  RetrieveMarketEventsFailureAction,
  RetrieveMarketEventsLoadingAction,
  RetrieveMarketEventsSuccessAction,
  RetrieveMyPastEventsAction,
  RetrieveMyPastEventsFailureAction,
  RetrieveMyPastEventsLoadingAction,
  RetrieveMyPastEventsSuccessAction,
  RetrieveMyUpcomingEventsAction,
  RetrieveMyUpcomingEventsFailureAction,
  RetrieveMyUpcomingEventsLoadingAction,
  RetrieveMyUpcomingEventsSuccessAction,
  RetrieveRelevantUserEventsAction,
  RetrieveRelevantUserEventsFailureAction,
  RetrieveRelevantUserEventsLoadingAction,
  RetrieveRelevantUserEventsSuccessAction,
  RetrieveSuccessAction,
  RETRIEVE_RELEVANT_USER_EVENTS,
  RETRIEVE_RELEVANT_USER_EVENTS_FAILURE,
  RETRIEVE_RELEVANT_USER_EVENTS_LOADING,
  RETRIEVE_RELEVANT_USER_EVENTS_SUCCESS,
  RETRIEVE_EVENTS,
  RETRIEVE_EVENTS_FAILURE,
  RETRIEVE_EVENTS_LOADING,
  RETRIEVE_EVENTS_SUCCESS,
  RETRIEVE_EVENT_TEASERS,
  RETRIEVE_EVENT_TEASERS_FAILURE,
  RETRIEVE_EVENT_TEASERS_LOADING,
  RETRIEVE_EVENT_TEASERS_SUCCESS,
  RETRIEVE_MARKET_EVENTS,
  RETRIEVE_MARKET_EVENTS_FAILURE,
  RETRIEVE_MARKET_EVENTS_LOADING,
  RETRIEVE_MARKET_EVENTS_SUCCESS,
  RETRIEVE_MY_PAST_EVENTS,
  RETRIEVE_MY_PAST_EVENTS_FAILURE,
  RETRIEVE_MY_PAST_EVENTS_LOADING,
  RETRIEVE_MY_PAST_EVENTS_SUCCESS,
  RETRIEVE_MY_UPCOMING_EVENTS,
  RETRIEVE_MY_UPCOMING_EVENTS_FAILURE,
  RETRIEVE_MY_UPCOMING_EVENTS_LOADING,
  RETRIEVE_MY_UPCOMING_EVENTS_SUCCESS,
  SelectEventAction,
  SelectEventFailureAction,
  SelectEventSuccessAction,
  SELECT_EVENT,
  SELECT_EVENT_FAILURE,
  SELECT_EVENT_SUCCESS,
  RETRIEVE_RELEVANT_USER_MARKET_EVENTS,
  RETRIEVE_RELEVANT_USER_MARKET_EVENTS_SUCCESS,
  RETRIEVE_RELEVANT_USER_MARKET_EVENTS_FAILURE,
  RETRIEVE_RELEVANT_USER_MARKET_EVENTS_LOADING,
  RetrieveRelevantUserMarketEventsAction,
  RetrieveRelevantUserMarketEventsSuccessAction,
  RetrieveRelevantUserMarketEventsFailureAction,
  RetrieveRelevantUserMarketEventsLoadingAction,
  RETRIEVE_MENU_EVENTS,
  RETRIEVE_MENU_EVENTS_FAILURE,
  RETRIEVE_MENU_EVENTS_SUCCESS,
  RetrieveMenuEventsAction,
  RetrieveMenuEventsFailureAction,
  RetrieveMenuEventsSuccessAction
} from './types';

export const EVENTS_DEFAULT_LIMIT = 12;
const doRetrieve = <
  T extends
    | RetrieveEventsAction
    | RetrieveMyPastEventsAction
    | RetrieveMyUpcomingEventsAction
    | RetrieveMenuEventsAction
    | RetrieveRelevantUserEventsAction = RetrieveEventsAction
>(
  type: T['type']
) => ({
  limit = EVENTS_DEFAULT_LIMIT,
  offset = 0,
  slug = ''
}: { limit?: number; offset?: number; slug?: string } = {}): Pick<T, 'type'> & RetrieveAction => ({
  type,
  limit,
  offset,
  slug
});

const doRetrieveFailure = <
  T extends
    | RetrieveEventsFailureAction
    | RetrieveEventTeasersFailureAction
    | RetrieveMyPastEventsFailureAction
    | RetrieveMyUpcomingEventsFailureAction
    | RetrieveRelevantUserEventsFailureAction
    | RetrieveMenuEventsFailureAction
>(
  type: T['type']
) => (error?: RequestResult<void>): Pick<T, 'type'> & RetrieveFailureAction => ({ type, error });

const doRetrieveSuccess = <
  T extends
    | RetrieveEventsSuccessAction
    | RetrieveEventTeasersSuccessAction
    | RetrieveMyPastEventsSuccessAction
    | RetrieveMyUpcomingEventsSuccessAction
    | RetrieveRelevantUserEventsSuccessAction
    | RetrieveMenuEventsSuccessAction
>(
  type: T['type']
) => (events: CMSEvent[], total: number): Pick<T, 'type'> & RetrieveSuccessAction => ({ type, events, total });

export const doRetrieveEvents = doRetrieve<RetrieveEventsAction>(RETRIEVE_EVENTS);
export const doRetrieveEventsFailure = doRetrieveFailure<RetrieveEventsFailureAction>(RETRIEVE_EVENTS_FAILURE);
export const doRetrieveEventsSuccess = doRetrieveSuccess<RetrieveEventsSuccessAction>(RETRIEVE_EVENTS_SUCCESS);
export const doRetrieveEventsLoading = (): RetrieveEventsLoadingAction => ({
  type: RETRIEVE_EVENTS_LOADING
});

export const doRetrieveMenuEvents = doRetrieve<RetrieveMenuEventsAction>(RETRIEVE_MENU_EVENTS);
export const doRetrieveMenuEventsFailure = doRetrieveFailure<RetrieveMenuEventsFailureAction>(
  RETRIEVE_MENU_EVENTS_FAILURE
);
export const doRetrieveMenuEventsSuccess = doRetrieveSuccess<RetrieveMenuEventsSuccessAction>(
  RETRIEVE_MENU_EVENTS_SUCCESS
);

export const doRetrieveRelevantUserEvents = doRetrieve<RetrieveRelevantUserEventsAction>(RETRIEVE_RELEVANT_USER_EVENTS);
export const doRetrieveRelevantUserEventsFailure = doRetrieveFailure<RetrieveRelevantUserEventsFailureAction>(
  RETRIEVE_RELEVANT_USER_EVENTS_FAILURE
);
export const doRetrieveRelevantUserEventsSuccess = doRetrieveSuccess<RetrieveRelevantUserEventsSuccessAction>(
  RETRIEVE_RELEVANT_USER_EVENTS_SUCCESS
);
export const doRetrieveRelevantUserEventsLoading = (): RetrieveRelevantUserEventsLoadingAction => ({
  type: RETRIEVE_RELEVANT_USER_EVENTS_LOADING
});

export const doRetrieveEventTeasers = (limit: number, ignoreEvents: number[] = []): RetrieveEventTeasersAction => ({
  type: RETRIEVE_EVENT_TEASERS,
  limit,
  ignoreEvents
});
export const doRetrieveEventTeasersFailure = doRetrieveFailure<RetrieveEventTeasersFailureAction>(
  RETRIEVE_EVENT_TEASERS_FAILURE
);
export const doRetrieveEventTeasersSuccess = doRetrieveSuccess<RetrieveEventTeasersSuccessAction>(
  RETRIEVE_EVENT_TEASERS_SUCCESS
);
export const doRetrieveEventTeasersLoading = (): RetrieveEventTeasersLoadingAction => ({
  type: RETRIEVE_EVENT_TEASERS_LOADING
});

export const doRetrieveMyUpcomingEvents = doRetrieve<RetrieveMyUpcomingEventsAction>(RETRIEVE_MY_UPCOMING_EVENTS);
export const doRetrieveMyUpcomingEventsFailure = doRetrieveFailure<RetrieveMyUpcomingEventsFailureAction>(
  RETRIEVE_MY_UPCOMING_EVENTS_FAILURE
);
export const doRetrieveMyUpcomingEventsSuccess = doRetrieveSuccess<RetrieveMyUpcomingEventsSuccessAction>(
  RETRIEVE_MY_UPCOMING_EVENTS_SUCCESS
);
export const doRetrieveMyUpcomingEventsLoading = (): RetrieveMyUpcomingEventsLoadingAction => ({
  type: RETRIEVE_MY_UPCOMING_EVENTS_LOADING
});

export const doRetrieveMyPastEvents = doRetrieve<RetrieveMyPastEventsAction>(RETRIEVE_MY_PAST_EVENTS);
export const doRetrieveMyPastEventsFailure = doRetrieveFailure<RetrieveMyPastEventsFailureAction>(
  RETRIEVE_MY_PAST_EVENTS_FAILURE
);
export const doRetrieveMyPastEventsSuccess = doRetrieveSuccess<RetrieveMyPastEventsSuccessAction>(
  RETRIEVE_MY_PAST_EVENTS_SUCCESS
);
export const doRetrieveMyPastEventsLoading = (): RetrieveMyPastEventsLoadingAction => ({
  type: RETRIEVE_MY_PAST_EVENTS_LOADING
});

export const doRetrieveMarketEvents = (slug: string): RetrieveMarketEventsAction => ({
  type: RETRIEVE_MARKET_EVENTS,
  slug
});

export const doRetrieveMarketEventsSuccess = (events: CMSEvent[]): RetrieveMarketEventsSuccessAction => ({
  type: RETRIEVE_MARKET_EVENTS_SUCCESS,
  events
});

export const doRetrieveMarketEventsFailure = (error?: RequestResult<void>): RetrieveMarketEventsFailureAction => ({
  type: RETRIEVE_MARKET_EVENTS_FAILURE,
  error
});

export const doRetrieveMarketEventsLoading = (): RetrieveMarketEventsLoadingAction => ({
  type: RETRIEVE_MARKET_EVENTS_LOADING
});

export const doRetrieveRelevantUserMarketEvents = (slug: string): RetrieveRelevantUserMarketEventsAction => ({
  type: RETRIEVE_RELEVANT_USER_MARKET_EVENTS,
  slug
});

export const doRetrieveRelevantUserMarketEventsSuccess = (
  events: CMSEvent[]
): RetrieveRelevantUserMarketEventsSuccessAction => ({
  type: RETRIEVE_RELEVANT_USER_MARKET_EVENTS_SUCCESS,
  events
});

export const doRetrieveRelevantUserMarketEventsFailure = (
  error?: RequestResult<void>
): RetrieveRelevantUserMarketEventsFailureAction => ({
  type: RETRIEVE_RELEVANT_USER_MARKET_EVENTS_FAILURE,
  error
});

export const doRetrieveRelevantUserMarketEventsLoading = (): RetrieveRelevantUserMarketEventsLoadingAction => ({
  type: RETRIEVE_RELEVANT_USER_MARKET_EVENTS_LOADING
});

export const doSelectEvent = (drupalNodeID: SelectEventAction['drupalNodeID']): SelectEventAction => ({
  type: SELECT_EVENT,
  drupalNodeID
});

export const doSelectEventSuccess = (event: SelectEventSuccessAction['event']): SelectEventSuccessAction => ({
  type: SELECT_EVENT_SUCCESS,
  event
});

export const doSelectEventFailure = (error: SelectEventFailureAction['error']): SelectEventFailureAction => ({
  type: SELECT_EVENT_FAILURE,
  error
});
