import { CMSConsultant } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const SET_MODAL_CONSULTANT = 'consultant/SET_MODAL_CONSULTANT';
export const SET_CONSULTANT_EMAIL_DATA = 'consultant/SET_CONSULTANT_EMAIL_DATA';

export const RETRIEVE_MARKET_CONSULTANT = 'consultant/RETRIEVE_MARKET_CONSULTANT';
export const RETRIEVE_MARKET_CONSULTANT_SUCCESS = 'consultant/RETRIEVE_MARKET_CONSULTANT_SUCCESS';
export const RETRIEVE_MARKET_CONSULTANT_FAILURE = 'consultant/RETRIEVE_MARKET_CONSULTANT_FAILURE';

export const RETRIEVE_USER_CONSULTANT_LIST = 'consultant/RETRIEVE_USER_CONSULTANT_LIST';
export const RETRIEVE_USER_CONSULTANT_LIST_SUCCESS = 'consultant/RETRIEVE_USER_CONSULTANT_LIST_SUCCESS';
export const RETRIEVE_USER_CONSULTANT_LIST_FAILURE = 'consultant/RETRIEVE_USER_CONSULTANT_LIST_FAILURE';

export const RETRIEVE_CONSULTANT_BY_SLUG = 'consultant/RETRIEVE_CONSULTANT_BY_SLUG';
export const RETRIEVE_CONSULTANT_BY_SLUG_SUCCESS = 'consultant/RETRIEVE_CONSULTANT_BY_SLUG_SUCCESS';
export const RETRIEVE_CONSULTANT_BY_SLUG_FAILURE = 'consultant/RETRIEVE_CONSULTANT_BY_SLUG_FAILURE';

export const RETRIEVE_GENERAL_CONSULTANT = 'consultant/RETRIEVE_GENERAL_CONSULTANT';
export const RETRIEVE_GENERAL_CONSULTANT_SUCCESS = 'consultant/RETRIEVE_GENERAL_CONSULTANT_SUCCESS';
export const RETRIEVE_GENERAL_CONSULTANT_FAILURE = 'consultant/RETRIEVE_GENERAL_CONSULTANT_FAILURE';

export const SEND_CONSULTANT_EMAIL = 'consultant/SEND_CONSULTANT_EMAIL';
export const SEND_CONSULTANT_EMAIL_SUCCESS = 'consultant/SEND_CONSULTANT_EMAIL_SUCCESS';
export const SEND_CONSULTANT_EMAIL_FAILURE = 'consultant/SEND_CONSULTANT_EMAIL_FAILURE';

export interface SetModalConsultantAction {
  type: typeof SET_MODAL_CONSULTANT;
  consultant?: CMSConsultant;
  shouldClearOnClose?: boolean;
}

export interface SetConsultantEmailDataAction {
  type: typeof SET_CONSULTANT_EMAIL_DATA;
  data?: {
    additionalContent: string;
    marketId?: number | null;
  };
}

export interface RetrieveMarketConsultantAction {
  type: typeof RETRIEVE_MARKET_CONSULTANT;
  marketSlug: string;
}
export interface RetrieveMarketConsultantSuccessAction {
  type: typeof RETRIEVE_MARKET_CONSULTANT_SUCCESS;
  marketConsultant: CMSConsultant;
}
export interface RetrieveMarketConsultantFailureAction {
  type: typeof RETRIEVE_MARKET_CONSULTANT_FAILURE;
  error: RequestResult;
}

export interface RetrieveUserConsultantListAction {
  type: typeof RETRIEVE_USER_CONSULTANT_LIST;
}
export interface RetrieveUserConsultantListSuccessAction {
  type: typeof RETRIEVE_USER_CONSULTANT_LIST_SUCCESS;
  consultantList: CMSConsultant[];
}
export interface RetrieveUserConsultantListFailureAction {
  type: typeof RETRIEVE_USER_CONSULTANT_LIST_FAILURE;
  error: RequestResult;
}

export interface RetrieveConsultantBySlugAction {
  type: typeof RETRIEVE_CONSULTANT_BY_SLUG;
  consultantSlug: string;
}
export interface RetrieveConsultantBySlugSuccessAction {
  type: typeof RETRIEVE_CONSULTANT_BY_SLUG_SUCCESS;
  consultant: CMSConsultant;
}
export interface RetrieveConsultantBySlugFailureAction {
  type: typeof RETRIEVE_CONSULTANT_BY_SLUG_FAILURE;
  error: RequestResult;
}

export interface RetrieveGeneralConsultantAction {
  type: typeof RETRIEVE_GENERAL_CONSULTANT;
}
export interface RetrieveGeneralConsultantSuccessAction {
  type: typeof RETRIEVE_GENERAL_CONSULTANT_SUCCESS;
  consultant: CMSConsultant;
}
export interface RetrieveGeneralConsultantFailureAction {
  type: typeof RETRIEVE_GENERAL_CONSULTANT_FAILURE;
  error: RequestResult;
}

export interface SendConsultantEmailAction {
  type: typeof SEND_CONSULTANT_EMAIL;
  data: {
    marketId?: number | null;
    body: string;
    subject: string;
    email: string;
    page?: string;
  };
}
export interface SendConsultantEmailSuccessAction {
  type: typeof SEND_CONSULTANT_EMAIL_SUCCESS;
}
export interface SendConsultantEmailFailureAction {
  type: typeof SEND_CONSULTANT_EMAIL_FAILURE;
  error?: RequestResult;
}
