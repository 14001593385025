import { BusinessType } from 'src/redux/modules/businessType/interfaces';
import { User } from 'src/redux/modules/user/interfaces';

export type BusinessTypeEnumerable = 'physical' | 'service' | 'hybrid';

export const businessTypeInternalName2BusinessType = (
  internalName?: string,
  noDefault?: boolean
): BusinessTypeEnumerable | null => {
  if (internalName == 'Physical goods') return 'physical';
  if (internalName == 'Software or Assets') return 'service';
  if (internalName == 'Software or Assets with physical components') return 'hybrid';

  //default
  return noDefault ? null : 'physical';
};

export const user2BusinessType = (user: User | undefined, noDefault?: boolean): BusinessTypeEnumerable | null => {
  return businessTypeInternalName2BusinessType(user?.businessType?.internalName, noDefault);
};

export const businessTypes2BusinessTypesIds = (businessTypes: BusinessType[] | undefined): (number | undefined)[] => {
  const physicalBusinessTypeId = businessTypes?.find(item => item.internalName == 'Physical goods')?.id;
  const serviceBusinessTypeId = businessTypes?.find(item => item.internalName == 'Software or Assets')?.id;
  const hybridBusinessTypeId = businessTypes?.find(
    item => item.internalName == 'Software or Assets with physical components'
  )?.id;

  return [physicalBusinessTypeId, serviceBusinessTypeId, hybridBusinessTypeId];
};

export const getUserBusinessTypeId = (user: User | undefined): string => {
  const internalName = user?.businessType?.internalName;

  if (internalName == 'Physical goods') return '1';
  if (internalName == 'Software or Assets') return '2';
  if (internalName == 'Software or Assets with physical components') return '3';

  return '1';
};

export type BusinessTypeIntercomEnumerable =
  | 'Physical goods'
  | 'Software or Services'
  | 'Software or Services with physical components';

export const businessType2IntercomBusinessType = (
  businessType: BusinessTypeEnumerable | null
): BusinessTypeIntercomEnumerable => {
  if (businessType == 'physical') return 'Physical goods';
  if (businessType == 'service') return 'Software or Services';
  if (businessType == 'hybrid') return 'Software or Services with physical components';
  else return 'Physical goods';
};

export const user2IntercomBusinessType = (user: User | undefined): BusinessTypeIntercomEnumerable | null => {
  const businessType = businessTypeInternalName2BusinessType(user?.businessType?.internalName, true);
  if (businessType) return businessType2IntercomBusinessType(businessType);
  return null;
};

export const businessTypeFlags2BusinessType = (
  isPhysical: string,
  hasHardwareComponents: string
): BusinessTypeEnumerable => {
  return isPhysical === 'true' ? 'physical' : hasHardwareComponents === 'true' ? 'hybrid' : 'service';
};
