import { RequestResult } from '../../../interfaces/requests';
import { CMSEvent } from './interfaces';

export const RETRIEVE_MARKET_EVENTS = 'events/RETRIEVE_MARKET_EVENTS';
export const RETRIEVE_MARKET_EVENTS_SUCCESS = 'events/RETRIEVE_MARKET_EVENTS_SUCCESS';
export const RETRIEVE_MARKET_EVENTS_FAILURE = 'events/RETRIEVE_MARKET_EVENTS_FAILURE';
export const RETRIEVE_MARKET_EVENTS_LOADING = 'events/RETRIEVE_MARKET_EVENTS_LOADING';
export const RETRIEVE_RELEVANT_USER_MARKET_EVENTS = 'events/RETRIEVE_RELEVANT_USER_MARKET_EVENTS';
export const RETRIEVE_RELEVANT_USER_MARKET_EVENTS_SUCCESS = 'events/RETRIEVE_RELEVANT_USER_MARKET_EVENTS_SUCCESS';
export const RETRIEVE_RELEVANT_USER_MARKET_EVENTS_FAILURE = 'events/RETRIEVE_RELEVANT_USER_MARKET_EVENTS_FAILURE';
export const RETRIEVE_RELEVANT_USER_MARKET_EVENTS_LOADING = 'events/RETRIEVE_RELEVANT_USER_MARKET_EVENTS_LOADING';
export const RETRIEVE_EVENTS = 'events/RETRIEVE_EVENTS';
export const RETRIEVE_EVENTS_SUCCESS = 'events/RETRIEVE_EVENTS_SUCCESS';
export const RETRIEVE_EVENTS_FAILURE = 'events/RETRIEVE_EVENTS_FAILURE';
export const RETRIEVE_EVENTS_LOADING = 'events/RETRIEVE_EVENTS_LOADING';
export const RETRIEVE_MENU_EVENTS = 'events/RETRIEVE_MENU_EVENTS';
export const RETRIEVE_MENU_EVENTS_SUCCESS = 'events/RETRIEVE_MENU_EVENTS_SUCCESS';
export const RETRIEVE_MENU_EVENTS_FAILURE = 'events/RETRIEVE_MENU_EVENTS_FAILURE';
export const RETRIEVE_RELEVANT_USER_EVENTS = 'events/RETRIEVE_RELEVANT_USER_EVENTS';
export const RETRIEVE_RELEVANT_USER_EVENTS_SUCCESS = 'events/RETRIEVE_RELEVANT_USER_EVENTS_SUCCESS';
export const RETRIEVE_RELEVANT_USER_EVENTS_FAILURE = 'events/RETRIEVE_RELEVANT_USER_EVENTS_FAILURE';
export const RETRIEVE_RELEVANT_USER_EVENTS_LOADING = 'events/RETRIEVE_RELEVANT_USER_EVENTS_LOADING';
export const RETRIEVE_EVENT_TEASERS = 'events/RETRIEVE_EVENT_TEASERS';
export const RETRIEVE_EVENT_TEASERS_SUCCESS = 'events/RETRIEVE_EVENT_TEASERS_SUCCESS';
export const RETRIEVE_EVENT_TEASERS_FAILURE = 'events/RETRIEVE_EVENT_TEASERS_FAILURE';
export const RETRIEVE_EVENT_TEASERS_LOADING = 'events/RETRIEVE_EVENT_TEASERS_LOADING';
export const RETRIEVE_MY_UPCOMING_EVENTS = 'events/RETRIEVE_MY_UPCOMING_EVENTS';
export const RETRIEVE_MY_UPCOMING_EVENTS_SUCCESS = 'events/RETRIEVE_MY_UPCOMING_EVENTS_SUCCESS';
export const RETRIEVE_MY_UPCOMING_EVENTS_FAILURE = 'events/RETRIEVE_MY_UPCOMING_EVENTS_FAILURE';
export const RETRIEVE_MY_UPCOMING_EVENTS_LOADING = 'events/RETRIEVE_MY_UPCOMING_EVENTS_LOADING';
export const RETRIEVE_MY_PAST_EVENTS = 'events/RETRIEVE_MY_PAST_EVENTS';
export const RETRIEVE_MY_PAST_EVENTS_SUCCESS = 'events/RETRIEVE_MY_PAST_EVENTS_SUCCESS';
export const RETRIEVE_MY_PAST_EVENTS_FAILURE = 'events/RETRIEVE_MY_PAST_EVENTS_FAILURE';
export const RETRIEVE_MY_PAST_EVENTS_LOADING = 'events/RETRIEVE_MY_PAST_EVENTS_LOADING';
export const SELECT_EVENT = 'events/SELECT_EVENT';
export const SELECT_EVENT_SUCCESS = 'events/SELECT_EVENT_SUCCESS';
export const SELECT_EVENT_FAILURE = 'events/SELECT_EVENT_FAILURE';

export interface RetrieveAction {
  limit: number;
  offset: number;
  slug: string;
}

export interface RetrieveSuccessAction {
  events: CMSEvent[];
  total: number;
}

export interface RetrieveFailureAction {
  error?: RequestResult<void>;
}

export interface RetrieveEventsAction extends RetrieveAction {
  type: typeof RETRIEVE_EVENTS;
}

export interface RetrieveEventsSuccessAction extends RetrieveSuccessAction {
  type: typeof RETRIEVE_EVENTS_SUCCESS;
}

export interface RetrieveEventsFailureAction extends RetrieveFailureAction {
  type: typeof RETRIEVE_EVENTS_FAILURE;
}

export interface RetrieveMenuEventsAction extends RetrieveAction {
  type: typeof RETRIEVE_MENU_EVENTS;
}

export interface RetrieveMenuEventsSuccessAction extends RetrieveSuccessAction {
  type: typeof RETRIEVE_MENU_EVENTS_SUCCESS;
}

export interface RetrieveMenuEventsFailureAction extends RetrieveFailureAction {
  type: typeof RETRIEVE_MENU_EVENTS_FAILURE;
}

export interface RetrieveEventsLoadingAction {
  type: typeof RETRIEVE_EVENTS_LOADING;
}
export interface RetrieveRelevantUserEventsAction extends RetrieveAction {
  type: typeof RETRIEVE_RELEVANT_USER_EVENTS;
}

export interface RetrieveRelevantUserEventsSuccessAction extends RetrieveSuccessAction {
  type: typeof RETRIEVE_RELEVANT_USER_EVENTS_SUCCESS;
}

export interface RetrieveRelevantUserEventsFailureAction extends RetrieveFailureAction {
  type: typeof RETRIEVE_RELEVANT_USER_EVENTS_FAILURE;
}

export interface RetrieveRelevantUserEventsLoadingAction {
  type: typeof RETRIEVE_RELEVANT_USER_EVENTS_LOADING;
}

export interface RetrieveEventTeasersAction {
  type: typeof RETRIEVE_EVENT_TEASERS;
  limit: number;
  ignoreEvents?: number[];
}
export interface RetrieveEventTeasersSuccessAction extends RetrieveSuccessAction {
  type: typeof RETRIEVE_EVENT_TEASERS_SUCCESS;
}

export interface RetrieveEventTeasersFailureAction extends RetrieveFailureAction {
  type: typeof RETRIEVE_EVENT_TEASERS_FAILURE;
}

export interface RetrieveEventTeasersLoadingAction {
  type: typeof RETRIEVE_EVENT_TEASERS_LOADING;
}

export interface RetrieveMyUpcomingEventsAction extends RetrieveAction {
  type: typeof RETRIEVE_MY_UPCOMING_EVENTS;
}

export interface RetrieveMyUpcomingEventsSuccessAction extends RetrieveSuccessAction {
  type: typeof RETRIEVE_MY_UPCOMING_EVENTS_SUCCESS;
}

export interface RetrieveMyUpcomingEventsFailureAction extends RetrieveFailureAction {
  type: typeof RETRIEVE_MY_UPCOMING_EVENTS_FAILURE;
}

export interface RetrieveMyUpcomingEventsLoadingAction {
  type: typeof RETRIEVE_MY_UPCOMING_EVENTS_LOADING;
}

export interface RetrieveMyPastEventsAction extends RetrieveAction {
  type: typeof RETRIEVE_MY_PAST_EVENTS;
}

export interface RetrieveMyPastEventsSuccessAction extends RetrieveSuccessAction {
  type: typeof RETRIEVE_MY_PAST_EVENTS_SUCCESS;
}

export interface RetrieveMyPastEventsFailureAction extends RetrieveFailureAction {
  type: typeof RETRIEVE_MY_PAST_EVENTS_FAILURE;
}

export interface RetrieveMyPastEventsLoadingAction extends RetrieveFailureAction {
  type: typeof RETRIEVE_MY_PAST_EVENTS_LOADING;
}

export interface RetrieveMarketEventsAction {
  type: typeof RETRIEVE_MARKET_EVENTS;
  slug: string;
}

export interface RetrieveMarketEventsSuccessAction {
  type: typeof RETRIEVE_MARKET_EVENTS_SUCCESS;
  events: CMSEvent[];
}

export interface RetrieveMarketEventsFailureAction {
  type: typeof RETRIEVE_MARKET_EVENTS_FAILURE;
  error?: RequestResult<void>;
}

export interface RetrieveMarketEventsLoadingAction {
  type: typeof RETRIEVE_MARKET_EVENTS_LOADING;
}

export interface RetrieveRelevantUserMarketEventsAction {
  type: typeof RETRIEVE_RELEVANT_USER_MARKET_EVENTS;
  slug: string;
}

export interface RetrieveRelevantUserMarketEventsSuccessAction {
  type: typeof RETRIEVE_RELEVANT_USER_MARKET_EVENTS_SUCCESS;
  events: CMSEvent[];
}

export interface RetrieveRelevantUserMarketEventsFailureAction {
  type: typeof RETRIEVE_RELEVANT_USER_MARKET_EVENTS_FAILURE;
  error?: RequestResult<void>;
}

export interface RetrieveRelevantUserMarketEventsLoadingAction {
  type: typeof RETRIEVE_RELEVANT_USER_MARKET_EVENTS_LOADING;
}

export interface SelectEventAction {
  type: typeof SELECT_EVENT;
  drupalNodeID: string | number;
}

export interface SelectEventSuccessAction {
  type: typeof SELECT_EVENT_SUCCESS;
  event: CMSEvent;
}

export interface SelectEventFailureAction {
  type: typeof SELECT_EVENT_FAILURE;
  error: RequestResult;
}
