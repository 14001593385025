import { CMSArticle } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_ARTICLES = 'article/RETRIEVE_ARTICLES';
export const RETRIEVE_ARTICLES_SUCCESS = 'article/RETRIEVE_ARTICLES_SUCCESS';
export const RETRIEVE_ARTICLES_FAILURE = 'article/RETRIEVE_ARTICLES_FAILURE';
export const RETRIEVE_MENU_ARTICLES = 'article/RETRIEVE_MENU_ARTICLES';
export const RETRIEVE_MENU_ARTICLES_SUCCESS = 'article/RETRIEVE_MENU_ARTICLES_SUCCESS';
export const RETRIEVE_MENU_ARTICLES_FAILURE = 'article/RETRIEVE_MENU_ARTICLES_FAILURE';
export const SELECT_ARTICLE = 'article/SELECT_ARTICLE';
export const SELECT_ARTICLE_SUCCESS = 'article/SELECT_ARTICLE_SUCCESS';
export const SELECT_ARTICLE_FAILURE = 'article/SELECT_ARTICLE_FAILURE';

export interface RetrieveMenuArticlesAction {
  type: typeof RETRIEVE_MENU_ARTICLES;
}

export interface RetrieveMenuArticlesSuccessAction {
  type: typeof RETRIEVE_MENU_ARTICLES_SUCCESS;
  articles: CMSArticle[];
}

export interface RetrieveMenuArticlesFailureAction {
  type: typeof RETRIEVE_MENU_ARTICLES_FAILURE;
  error?: RequestResult<void>;
}

export interface RetrieveArticlesAction {
  type: typeof RETRIEVE_ARTICLES;
  limit: number;
  offset: number;
  market?: string;
  industry?: string;
}

export interface RetrieveArticlesSuccessAction {
  type: typeof RETRIEVE_ARTICLES_SUCCESS;
  articles: CMSArticle[];
  total: number;
}

export interface RetrieveArticlesFailureAction {
  type: typeof RETRIEVE_ARTICLES_FAILURE;
  error?: RequestResult<void>;
}

export interface SelectArticleAction {
  type: typeof SELECT_ARTICLE;
  drupalNodeID: string | number;
}

export interface SelectArticleSuccessAction {
  type: typeof SELECT_ARTICLE_SUCCESS;
  article: CMSArticle;
}

export interface SelectArticleFailureAction {
  type: typeof SELECT_ARTICLE_FAILURE;
  error: RequestResult;
}
