import {
  CHANGE_SEARCH,
  ChangeSearchAction,
  SEARCH_PRODUCTS,
  SEARCH_PRODUCTS_FAILURE,
  SEARCH_PRODUCTS_SUCCESS,
  SearchProductsAction,
  SearchProductsFailureAction,
  SearchProductsSuccessAction,
  SELECT_PRODUCT,
  DESELECT_PRODUCT,
  DESELECT_ALL_PRODUCTS,
  SelectProductAction,
  DeselectProductAction,
  SAVE_PRODUCTS,
  SAVE_PRODUCTS_FAILURE,
  SAVE_PRODUCTS_SUCCESS,
  SaveProductsAction,
  SaveProductsSuccessAction,
  SaveProductsFailureAction,
  RETRIEVE_PRODUCTS,
  RETRIEVE_PRODUCTS_SUCCESS,
  RETRIEVE_PRODUCTS_FAILURE,
  RetrieveProductsAction,
  RetrieveProductsSuccessAction,
  RetrieveProductsFailureAction,
  RETRIEVE_PRODUCTS_FULL,
  RETRIEVE_PRODUCTS_FULL_SUCCESS,
  RETRIEVE_PRODUCTS_FULL_FAILURE,
  RetrieveProductsFullAction,
  RetrieveProductsFullSuccessAction,
  RetrieveProductsFullFailureAction
} from './types';

export const doChangeSearch = (searchTerms: ChangeSearchAction['searchTerms']): ChangeSearchAction => ({
  searchTerms,
  type: CHANGE_SEARCH
});

export const doSearchProducts = (): SearchProductsAction => ({
  type: SEARCH_PRODUCTS
});

export const doSearchProductsSuccess = (
  searchResults: SearchProductsSuccessAction['searchResults']
): SearchProductsSuccessAction => ({
  searchResults,
  type: SEARCH_PRODUCTS_SUCCESS
});

export const doSearchProductsFailure = (error?: SearchProductsFailureAction['error']): SearchProductsFailureAction => ({
  error,
  type: SEARCH_PRODUCTS_FAILURE
});

export const doSelectProduct = (selected: SelectProductAction['selected']) => ({
  selected,
  type: SELECT_PRODUCT
});

export const doDeselectProduct = (selected: DeselectProductAction['selected']) => ({
  selected,
  type: DESELECT_PRODUCT
});

export const doDeselectAllProducts = () => ({
  type: DESELECT_ALL_PRODUCTS
});

export const doSaveProducts = (onFinish?: SaveProductsAction['onFinish']): SaveProductsAction => ({
  type: SAVE_PRODUCTS,
  onFinish
});

export const doSaveProductsSuccess = (): SaveProductsSuccessAction => ({
  type: SAVE_PRODUCTS_SUCCESS
});

export const doSaveProductsFailure = (error?: SearchProductsFailureAction['error']): SaveProductsFailureAction => ({
  error,
  type: SAVE_PRODUCTS_FAILURE
});

export const doRetrieveProducts = (): RetrieveProductsAction => ({
  type: RETRIEVE_PRODUCTS
});

export const doRetrieveProductsSuccess = (
  products: RetrieveProductsSuccessAction['products']
): RetrieveProductsSuccessAction => ({
  type: RETRIEVE_PRODUCTS_SUCCESS,
  products
});

export const doRetrieveProductsFailure = (
  error?: RetrieveProductsFailureAction['error']
): RetrieveProductsFailureAction => ({
  error,
  type: RETRIEVE_PRODUCTS_FAILURE
});

export const doRetrieveProductsFull = (): RetrieveProductsFullAction => ({
  type: RETRIEVE_PRODUCTS_FULL
});

export const doRetrieveProductsFullSuccess = (
  productsFull: RetrieveProductsFullSuccessAction['productsFull']
): RetrieveProductsFullSuccessAction => ({
  type: RETRIEVE_PRODUCTS_FULL_SUCCESS,
  productsFull
});

export const doRetrieveProductsFullFailure = (
  error?: RetrieveProductsFullFailureAction['error']
): RetrieveProductsFullFailureAction => ({
  type: RETRIEVE_PRODUCTS_FULL_FAILURE,
  error
});
