import {
  RETRIEVE_ARTICLES,
  RETRIEVE_ARTICLES_FAILURE,
  RETRIEVE_ARTICLES_SUCCESS,
  SELECT_ARTICLE,
  SELECT_ARTICLE_SUCCESS,
  RetrieveArticlesAction,
  RetrieveArticlesFailureAction,
  RetrieveArticlesSuccessAction,
  SelectArticleAction,
  SelectArticleSuccessAction,
  SelectArticleFailureAction,
  SELECT_ARTICLE_FAILURE,
  RETRIEVE_MENU_ARTICLES,
  RETRIEVE_MENU_ARTICLES_FAILURE,
  RETRIEVE_MENU_ARTICLES_SUCCESS,
  RetrieveMenuArticlesAction,
  RetrieveMenuArticlesFailureAction,
  RetrieveMenuArticlesSuccessAction
} from './types';
import { CMSArticle } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doRetrieveArticles = (
  limit?: number,
  offset?: number,
  market?: string,
  industry?: string
): RetrieveArticlesAction => ({
  type: RETRIEVE_ARTICLES,
  limit: limit || 12,
  offset: offset || 0,
  market,
  industry
});

export const doRetrieveArticlesFailure = (error?: RequestResult<void>): RetrieveArticlesFailureAction => ({
  error,
  type: RETRIEVE_ARTICLES_FAILURE
});

export const doRetrieveArticlesSuccess = (articles: CMSArticle[], total: number): RetrieveArticlesSuccessAction => ({
  type: RETRIEVE_ARTICLES_SUCCESS,
  articles,
  total
});

export const doRetrieveMenuArticles = (): RetrieveMenuArticlesAction => ({
  type: RETRIEVE_MENU_ARTICLES
});

export const doRetrieveMenuArticlesFailure = (error?: RequestResult<void>): RetrieveMenuArticlesFailureAction => ({
  error,
  type: RETRIEVE_MENU_ARTICLES_FAILURE
});

export const doRetrieveMenuArticlesSuccess = (articles: CMSArticle[]): RetrieveMenuArticlesSuccessAction => ({
  type: RETRIEVE_MENU_ARTICLES_SUCCESS,
  articles
});

export const doSelectArticle = (drupalNodeID: SelectArticleAction['drupalNodeID']): SelectArticleAction => ({
  type: SELECT_ARTICLE,
  drupalNodeID
});

export const doSelectArticleSuccess = (article: SelectArticleSuccessAction['article']): SelectArticleSuccessAction => ({
  type: SELECT_ARTICLE_SUCCESS,
  article
});

export const doSelectArticleFailure = (error: SelectArticleFailureAction['error']): SelectArticleFailureAction => ({
  type: SELECT_ARTICLE_FAILURE,
  error
});
