import { Report, Question, ReportTodo } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_REPORT = 'report/RETRIEVE_REPORT';
export const RETRIEVE_REPORT_SUCCESS = 'report/RETRIEVE_REPORT_SUCCESS';
export const RETRIEVE_REPORT_FAILURE = 'report/RETRIEVE_REPORT_FAILURE';

export const RETRIEVE_REPORT_UUID = 'report/RETRIEVE_REPORT_UUID';
export const RETRIEVE_REPORT_UUID_SUCCESS = 'report/RETRIEVE_REPORT_UUID_SUCCESS';
export const RETRIEVE_REPORT_UUID_FAILURE = 'report/RETRIEVE_REPORT_UUID_FAILURE';

export const RETRIEVE_REPORT_FULL = 'report/RETRIEVE_REPORT_FULL';
export const RETRIEVE_REPORT_FULL_SUCCESS = 'report/RETRIEVE_REPORT_FULL_SUCCESS';
export const RETRIEVE_REPORT_FULL_FAILURE = 'report/RETRIEVE_REPORT_FULL_FAILURE';

export const SEND_REPORT = 'report/SEND_REPORT';
export const SEND_REPORT_SUCCESS = 'report/SEND_REPORT_SUCCESS';
export const SEND_REPORT_FAILURE = 'report/SEND_REPORT_FAILURE';

export const UPDATE_REPORT = 'report/UPDATE_REPORT';
export const UPDATE_REPORT_SUCCESS = 'report/UPDATE_REPORT_SUCCESS';
export const UPDATE_REPORT_FAILURE = 'report/UPDATE_REPORT_FAILURE';

export const TOGGLE_REPORT_TODO_DONE = 'report/TOGGLE_REPORT_TODO_DONE';
export const SET_REPORT_TODO_DONE = 'report/SET_TODO_DONE';

export interface RetrieveNavigatorReportAction {
  type: typeof RETRIEVE_REPORT;
  uuid: string;
}

export interface RetrieveNavigatorReportSuccessAction {
  type: typeof RETRIEVE_REPORT_SUCCESS;
  report: any;
}

export interface RetrieveNavigatorReportFailureAction {
  type: typeof RETRIEVE_REPORT_FAILURE;
  error?: RequestResult<void>;
}

export interface RetrieveNavigatorReportUuidAction {
  type: typeof RETRIEVE_REPORT_UUID;
}

export interface RetrieveNavigatorReportUuidSuccessAction {
  type: typeof RETRIEVE_REPORT_UUID_SUCCESS;
  uuid: string;
}

export interface RetrieveNavigatorReportUuidFailureAction {
  type: typeof RETRIEVE_REPORT_UUID_FAILURE;
  error?: RequestResult<void>;
}

export interface SendNavigatorReportAction {
  type: typeof SEND_REPORT;
  questions: Question[];
  marketId: number;
  HSCodeId: number;
  keepCurrentReport?: boolean;
}

export interface SendNavigatorReportSuccessAction {
  type: typeof SEND_REPORT_SUCCESS;
  uuid: Report['uuid'];
}

export interface SendNavigatorReportFailureAction {
  type: typeof SEND_REPORT_FAILURE;
  error?: RequestResult<void>;
}

export interface RetrieveNavigatorReportFullAction {
  type: typeof RETRIEVE_REPORT_FULL;
}

export interface RetrieveNavigatorReportFullSuccessAction {
  type: typeof RETRIEVE_REPORT_FULL_SUCCESS;
  report: any;
}

export interface RetrieveNavigatorReportFullFailureAction {
  type: typeof RETRIEVE_REPORT_FULL_FAILURE;
  error?: RequestResult<void>;
}

export interface ToggleReportTodoDoneAction {
  type: typeof TOGGLE_REPORT_TODO_DONE;
  reportTask: ReportTodo;
  questionId: number;
  checked: boolean;
}

export interface SetReportTodoDoneAction {
  type: typeof SET_REPORT_TODO_DONE;
  reportTask: ReportTodo;
  questionId: number;
  checked: boolean;
}

export interface SendNavigatorReportAction {
  type: typeof SEND_REPORT;
  questions: Question[];
  marketId: number;
  HSCodeId: number;
}

export interface SendNavigatorReportSuccessAction {
  type: typeof SEND_REPORT_SUCCESS;
  uuid: Report['uuid'];
}

export interface SendNavigatorReportFailureAction {
  type: typeof SEND_REPORT_FAILURE;
  error?: RequestResult<void>;
}

export interface UpdateNavigatorReportAction {
  type: typeof UPDATE_REPORT;
  question: Question;
}

export interface UpdateNavigatorReportSuccessAction {
  type: typeof UPDATE_REPORT_SUCCESS;
}

export interface UpdateNavigatorReportFailureAction {
  type: typeof UPDATE_REPORT_FAILURE;
  error?: RequestResult<void>;
}
