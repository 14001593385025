import styled from 'styled-components';
import { TabletAndUp, LaptopAndUp } from '../../components/stylingUtils';

type SectionProps = {
  placementOrder?: number;
};

export default {
  Container: styled.div`
    margin: ${props => props.theme.spacing.default} auto;

    ${props => TabletAndUp(props)} {
      margin: ${props => props.theme.spacing.lg} auto;
    }

    ${props => LaptopAndUp(props)} {
      margin: ${props => props.theme.spacing.xxlg} auto;
    }
  `,
  Section: styled.div<SectionProps>`
    margin-bottom: ${props => props.theme.spacing.xmd};

    order: ${props => props.placementOrder};

    ${props => TabletAndUp(props)} {
      margin-bottom: ${props => props.theme.spacing.lgxx};
    }

    ${props => LaptopAndUp(props)} {
      margin-bottom: ${props => props.theme.spacing.xxxlg};
    }
  `,
  TitleSpacing: styled.div`
    margin-bottom: ${props => props.theme.spacing.sm};

    ${props => TabletAndUp(props)} {
      margin-bottom: ${props => props.theme.spacing.default};
    }

    ${props => LaptopAndUp(props)} {
      margin-bottom: ${props => props.theme.spacing.md};
    }
  `
};
