import { RequestResult } from '../../../interfaces/requests';
import { Product, SelectedProduct, SelectedProductFull } from './interfaces';

export const CHANGE_SEARCH = 'product/CHANGE_SEARCH';
export const SEARCH_PRODUCTS = 'product/SEARCH_PRODUCTS';
export const SEARCH_PRODUCTS_SUCCESS = 'product/SEARCH_PRODUCTS_SUCCESS';
export const SEARCH_PRODUCTS_FAILURE = 'product/SEARCH_PRODUCTS_FAILURE';
export const SELECT_PRODUCT = 'product/SELECT_PRODUCT';
export const DESELECT_PRODUCT = 'product/DESELECT_PRODUCT';
export const DESELECT_ALL_PRODUCTS = 'product/DESELECT_ALL_PRODUCTS';
export const SAVE_PRODUCTS = 'product/SAVE_PRODUCTS';
export const SAVE_PRODUCTS_SUCCESS = 'product/SAVE_PRODUCTS_SUCCESS';
export const SAVE_PRODUCTS_FAILURE = 'product/SAVE_PRODUCTS_FAILURE';
export const RETRIEVE_PRODUCTS = 'product/RETRIEVE_PRODUCTS';
export const RETRIEVE_PRODUCTS_SUCCESS = 'product/RETRIEVE_PRODUCTS_SUCCESS';
export const RETRIEVE_PRODUCTS_FAILURE = 'product/RETRIEVE_PRODUCTS_FAILURE';
export const RETRIEVE_PRODUCTS_FULL = 'product/RETRIEVE_PRODUCTS_FULL';
export const RETRIEVE_PRODUCTS_FULL_SUCCESS = 'product/RETRIEVE_PRODUCTS_FULL_SUCCESS';
export const RETRIEVE_PRODUCTS_FULL_FAILURE = 'product/RETRIEVE_PRODUCTS_FULL_FAILURE';

export interface ChangeSearchAction {
  type: typeof CHANGE_SEARCH;
  searchTerms: string;
}

export interface SearchProductsAction {
  type: typeof SEARCH_PRODUCTS;
}

export interface SearchProductsSuccessAction {
  type: typeof SEARCH_PRODUCTS_SUCCESS;
  searchResults: Product[];
}

export interface SearchProductsFailureAction {
  type: typeof SEARCH_PRODUCTS_FAILURE;
  error?: RequestResult<void>;
}

export interface SelectProductAction {
  type: typeof SELECT_PRODUCT;
  selected: SelectedProduct;
}

export interface DeselectProductAction {
  type: typeof DESELECT_PRODUCT;
  selected: SelectedProduct['HSCode'];
}

export interface DeselectAllProductsAction {
  type: typeof DESELECT_ALL_PRODUCTS;
}

export interface SaveProductsAction {
  type: typeof SAVE_PRODUCTS;
  onFinish?: (products?: SelectedProduct[]) => void;
}

export interface SaveProductsSuccessAction {
  type: typeof SAVE_PRODUCTS_SUCCESS;
}

export interface SaveProductsFailureAction {
  type: typeof SAVE_PRODUCTS_FAILURE;
  error?: RequestResult<void>;
}

export interface RetrieveProductsAction {
  type: typeof RETRIEVE_PRODUCTS;
}

export interface RetrieveProductsSuccessAction {
  type: typeof RETRIEVE_PRODUCTS_SUCCESS;
  products: SelectedProduct[];
}

export interface RetrieveProductsFailureAction {
  type: typeof RETRIEVE_PRODUCTS_FAILURE;
  error?: RequestResult<void>;
}

export interface RetrieveProductsFullAction {
  type: typeof RETRIEVE_PRODUCTS_FULL;
}

export interface RetrieveProductsFullSuccessAction {
  type: typeof RETRIEVE_PRODUCTS_FULL_SUCCESS;
  productsFull: SelectedProductFull[];
}

export interface RetrieveProductsFullFailureAction {
  type: typeof RETRIEVE_PRODUCTS_FULL_FAILURE;
  error?: RequestResult<void>;
}
